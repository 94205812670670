<script>
import {
    ArrowUpIcon,
    MailIcon,
    PhoneIcon,
    YoutubeIcon,
    MapPinIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    GitlabIcon,
    SmartphoneIcon,
    BookIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";

/**
 * Page-contact-detail component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        ArrowUpIcon,
        MailIcon,
        PhoneIcon,
        MapPinIcon,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        GithubIcon,
        YoutubeIcon,
        GitlabIcon,
        SmartphoneIcon,
        BookIcon
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="vh-100 d-flex align-items-center" style="background: url('images/template/contact-detail.jpg') center center;">
        <div class="bg-overlay bg-overlay-white"></div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
                    <div class="title-heading mt-5 pt-4">
                        <h1 class="heading">Contact Details</h1>
                        <p class="text-dark">Know more about  <span class="text-primary font-weight-bold">TREEP</span>, don't hesitate to contact us.</p>

                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <mail-icon class="fea icon-m-md text-dark mr-3"></mail-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0">Email</h5>
                                <a href="mailto:jerry.li@treep.cc" class="text-primary">jerry.li@treep.cc</a>
                            </div>
                        </div>

                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <phone-icon class="fea icon-m-md text-dark mr-3"></phone-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0">Phone</h5>
                                <a href="tel:+8615999956920" class="text-primary">+86 15999956920</a><br>
                                <a href="tel:+85254266686" class="text-primary">+852 54266686</a><br>
                                <a href="tel:+660935530595" class="text-primary">+66 0935530595</a>
                            </div>
                        </div>

                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <map-pin-icon class="fea icon-m-md text-dark mr-3"></map-pin-icon>
                            </div>
                            <div class="media-body content">
                                 <h5 class="title font-weight-bold mb-0">Wechat</h5>
                                 liyujerry
                            </div>
                        </div>
                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <smartphone-icon class="fea icon-m-md text-dark mr-3"></smartphone-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0">WhatsApp</h5>
                                <a href="https://wa.me/85254266686" class="text-primary">+852 54266686</a>
                            </div>
                        </div>
                        <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <smartphone-icon class="fea icon-m-md text-dark mr-3"></smartphone-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0">LINE</h5>
                                <a href="https://line.me/ti/p/fC4odSDT8U" class="text-primary">liyujerry</a>
                                <!-- https://line.me/R/nv/?liyujerry -->
                            </div>
                        </div>
                        <ul class="list-unstyled social-icon mb-0 mt-4">
                            <li class="list-inline-item"><a href="https://www.facebook.com/TREEPSYSTEM" target="_blank" class="rounded">
                                    <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a href="https://www.instagram.com/treeptreeptreep/" target="_blank" class="rounded">
                                    <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a href="https://www.xiaohongshu.com/user/profile/5ecb2ba70000000001006144" target="_blank" class="rounded">
                                 <book-icon class="fea icon-sm fea-social"></book-icon>
                                </a></li>
                            <li class="list-inline-item ml-1"><a href="https://www.linkedin.com/company/treeptechnology/" target="_blank" class="rounded">
                                    <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->
    <!--end section-->

    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

<style>
.modal-content {
    background-color: transparent;
}
</style>
